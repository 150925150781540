import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ExcelService } from '../theme/components/export-to-excel/excel.service';



import {
    PageHeaderComponent,
    ExportToExcelComponent,
    ChartistChart,
    ChartjsComponent,
    LoaderComponent,
    DisplayFailureComponent
} from './components';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';


const NGA_COMPONENTS = [
    PageHeaderComponent,
    SidebarComponent,
    FooterComponent,
    LoaderComponent,
    DisplayFailureComponent,
    ExportToExcelComponent,
    ChartistChart,
    ChartjsComponent
];

@NgModule({
  declarations: [
    ...NGA_COMPONENTS
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    ...NGA_COMPONENTS
  ],
  providers: [ExcelService]
})
export class NgaModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders> {
      ngModule: NgaModule,
      providers: [
      ]
    };
  }
}
