import { Routes, RouterModule, CanActivate } from '@angular/router';
import { Pages } from './pages.component';
import { ModuleWithProviders } from '@angular/core';
import { AuthGuardService } from '../shared/authguard.service';

export const routes: Routes = [
    {
        path: 'admin',
        component: Pages,
        children: [
            { path: '',canActivate: [AuthGuardService],  loadChildren: './dashboard/dashboard.module#DashboardModule' },
            { path: 'transRevenue',canActivate: [AuthGuardService],  loadChildren: './trans-revenue/trans-revenue.module#TransactionRevenueModule' },
            { path: 'vwoTestAnalysis',canActivate: [AuthGuardService], loadChildren: './vwo-test-analysis/vwo-test-analysis.module#VwoTestAnalysisModule' },
            { path: 'vgift',canActivate: [AuthGuardService], loadChildren: './vgift/vgift.module#VgiftModule' },
            { path: 'subRevenue',canActivate: [AuthGuardService], loadChildren: './sub-revenue/sub-revenue.module#SubRevenueModule' },
            { path: 'retention',canActivate: [AuthGuardService], loadChildren: './retention/retention.module#RetentionModule' },
            { path: 'churn',canActivate: [AuthGuardService], loadChildren: './subscription/subscription.module#SubscriptionModule' },
            { path: 'arppu',canActivate: [AuthGuardService], loadChildren: './arppu/arppu.module#ArppuModule' },
            { path: 'activity',canActivate: [AuthGuardService],loadChildren:'./activity-engagement/activity-engagement.module#ActivityEngagementModule'},
            { path: 'newUser',canActivate: [AuthGuardService], loadChildren:'./new-user/new-user.module#NewUserModule'},
            { path: 'activities',canActivate: [AuthGuardService], loadChildren:'./daily-activities/daily-activities.module#DailyActivitiesModule'},
            { path: 'onlineUser',canActivate: [AuthGuardService], loadChildren:'./daily-online-user/daily-online-user.module#DailyOnlineUserModule'},
            { path: 'countryType',canActivate: [AuthGuardService], loadChildren:'./ret-by-country/ret-by-country.module#RetentionByCountryModule'},
            { path: 'cohortUser',canActivate: [AuthGuardService], loadChildren:'./cohort/cohort.module#CohortModule'},
            { path: 'cmpgMetrics',canActivate: [AuthGuardService], loadChildren:'./cmpg-analytics/cmpg-analytics.module#CmpgAnalyticsModule'},
            // { path: 'cohortCountry',canActivate: [AuthGuardService], loadChildren:'./country-cohort/country-cohort.module#CountryCohortModule'},
            { path: 'cohortClient',canActivate: [AuthGuardService], loadChildren:'./cohort-client/cohort-client.module#CohortClientModule'},
            { path: 'retUser',canActivate: [AuthGuardService], loadChildren:'./cohort-user/cohort-user.module#CohortUserModule'},
            { path: 'manycamp',canActivate: [AuthGuardService], loadChildren:'./many-camp/many-camp.module#ManyCampModule'},
            { path: 'monthlyRev',canActivate: [AuthGuardService], loadChildren:'./monthly-revenue/monthly-revenue.module#MonthlyRevenueModule'},
            // { path: 'marketingRept',canActivate: [AuthGuardService], loadChildren:'./marketing-metrics/marketing-metrics.module#MarketingMetricsModule'},
            { path: 'autoRenew',canActivate: [AuthGuardService], loadChildren:'./auto-renew/auto-renew.module#AutoRenewModule'},
            // { path: 'subDailyRev',canActivate: [AuthGuardService], loadChildren:'./sub-daily-rev/sub-daily-rev.module#SubDailyRevModule'},
            { path: 'hourly',canActivate: [AuthGuardService], loadChildren:'./hourly-revenue/hourly-revenue.module#HourlyRevModule'},
            // { path: 'revPayingSource',canActivate: [AuthGuardService], loadChildren:'./paying-source/paying-source.module#PayingSourceModule'},
            { path: 'finance',canActivate: [AuthGuardService], loadChildren:'./finance/finance.module#FinanceModule'},
            { path: 'bogo',canActivate: [AuthGuardService], loadChildren:'./bogo/bogo.module#BogoModule'},
            { path: 'retTrendByUser',canActivate: [AuthGuardService], loadChildren:'./retention-trends/retention-trends.module#RetentionTrendsModule'},
            { path: 'retTrendByCountry',canActivate: [AuthGuardService], loadChildren:'./retention-country/retention-country.module#RetentionCountryModule'},
            { path: 'manycam',canActivate: [AuthGuardService], loadChildren:'./manycam/manycam.module#ManyCamModule'}  ,
            { path: 'paltalkmarketing',canActivate: [AuthGuardService], loadChildren:'./paltalk-marketing/paltalk-marketing.module#PaltalkMarketingModule'},
            { path: 'paltalkActiveSubs',canActivate: [AuthGuardService], loadChildren:'./paltalk-active-sub/paltalk-active-sub.module#PaltalkActiveSubModule'},
            { path: 'marketing',canActivate: [AuthGuardService], loadChildren:'./marketing/marketing.module#MarketingModule'}              
           
        ]
    },
    { path: 'unauthorized-access', loadChildren: '../pages/unauthorized/unauthorized.module#UnAuthorizedModule' },
    { path: 'session-expired', loadChildren: '../pages/session-expired/session-expired.module#SessionExpiredModule' },
    { path: '', loadChildren: './login/login.module#LoginModule' },
    { path: 'login', loadChildren: './login/login.module#LoginModule' }
];
export const routing: ModuleWithProviders = RouterModule.forChild(routes);
