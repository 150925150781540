import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ApplicationRef } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';

import { routing } from './app.routing';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';

import { AppComponent } from './app.component';
import { NgaModule } from './theme/nga.module';
// import { LeadSearchModule } from './theme/components/lead-search/lead-search.module';
import { PagesModule } from './pages/pages.module';

import { WebApiService } from './shared/webapi.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'; 
import { HttpErrorInterceptor } from './http.interceptor';



@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,

  ],
  imports: [
    BrowserModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgaModule.forRoot(),
    PagesModule,
    // LeadSearchModule,
    routing,
    HttpClientModule
  ],
  providers: [WebApiService, { provide: LocationStrategy, useClass: PathLocationStrategy },{
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }],
})
export class AppModule { }
