import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) { }
  canActivate(): boolean {
    const status = this.auth.isAuthenticated();
    if (status === 0) {
      this.router.navigate(['login']);
      return false;
    }

    if (status === 1) {
      return true;
    }

    if (status === 2) {
      this.router.navigate(['session-expired']);
      return false;
    }
  }
}
