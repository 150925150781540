import { Injectable } from '@angular/core';
import { JwtHelper } from 'angular2-jwt';
@Injectable()
export class AuthService {

  constructor() {

  }

  public isAuthenticated(): number {
    const token = localStorage.getItem('BI_USER_TOKEN');
    if (token === null) {
      return 0;
    } else {
      const payload = new JwtHelper().decodeToken(token);
      if (payload.entitlements.indexOf('REP') === -1) { 
        return 0;
      }
      const now = Date.now() / 1000;
      const validToken = payload.exp > now
      if (!validToken) {
        return 2;
      }
      return 1;
    }
  }
}