import { environment } from '../../environments/environment';

export class UrlConstants {
    public static APPURL = environment.APPURL;
    public static Login: string = 'https://admin.paltalk.com/adm/ControllerServlet?RequestId=adm.Authenticate';
    // public static Login: string = 'https://admin.paltalk.com/adm/ControllerServlet?RequestId=adm.Authenticate';

    // Dashboard APIs
    public static getPaltalkRevenuveData: string = UrlConstants.APPURL + '/getdata';
    public static postAskBiData: string = UrlConstants.APPURL + '/askbi-submit';
    public static getFilterData: string = UrlConstants.APPURL + '/getfilterdata';
}