import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { routing } from './pages.routing';
import { NgaModule } from '../theme/nga.module';
import { Pages } from './pages.component';
import { AuthService } from '../shared/auth.service';
import { AuthGuardService } from '../shared/authguard.service';
import { JwtHelper } from 'angular2-jwt';

@NgModule({
  imports: [CommonModule, NgaModule, routing],
  declarations: [Pages],
  providers: [
    AuthService,
    AuthGuardService,
    JwtHelper
  ]
})
export class PagesModule {
}
