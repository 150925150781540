import { Component, Input } from '@angular/core';
import { ExcelService } from './excel.service';

@Component({
  selector: 'app-export-to-excel',
  templateUrl: './exportToExcel.html',
  styleUrls: ['./exportToExcel.scss']
})
export class ExportToExcelComponent {

  @Input() dataObj;
  @Input() fileName;

  constructor(private excelService: ExcelService) {
  }

  exportToExcel() {
    this.excelService.exportAsExcelFile(this.dataObj, this.fileName);
  }
}
