import { Component, OnInit, ElementRef, HostBinding, HostListener, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'drop-down',
  templateUrl: './dropdown.html',
  styleUrls: ['./dropdown.scss']
})
export class DropDownComponent implements OnInit {
  @Input() dropDownObj;
  @Input() disable;
  @Input() dropDownModel;
  @Input() parentClass: string;
  @Output() onMenuSelect: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
   
  }

   onChangeObj(item){
    this.onMenuSelect.emit(item);
 }
}
