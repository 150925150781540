import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-display-failure',
  templateUrl: './display-failure.component.html',
  styleUrls: ['./display-failure.component.scss']
})
export class DisplayFailureComponent implements OnInit {

  @Input() displayMessage: string;
  @Input() scrollToTop: boolean;
  @Input() autoClose: boolean;
  @Output() alertClose: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    if (this.scrollToTop) {
      window.scroll(0, 0);
    }
    if (this.autoClose) {
      const _this = this
      setTimeout(function () {
        $('#showFailure').slideUp('slow', function () {
          $('#showFailure').hide();
        });
        _this.alertClose.emit();
      }, 5000);
    }
  }

  public onClose(event) {
    this.alertClose.emit(event);
  }

}
