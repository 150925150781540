import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { environment } from '../../environments/environment';

@Injectable()
export class WebApiService {

  public token: string;

  constructor(private http: HttpClient) {
  }

  public headers = new HttpHeaders();

  // url    : The api url which used to call the function
  // params : The variable which is used to pass the query string or the params

  // Function for GET operations
  public getFunction(url, params): Promise<any> {
    try {
      params = (params != null) ? params : '';
      let skipAuthBearer = false;
      if (url.startsWith(environment.APPURL)) {
        skipAuthBearer = true;
      }
      this.setHeaders(true, skipAuthBearer);
      const token = localStorage.getItem('BI_USER_TOKEN');
      if (params) {
        params = `${params}&token=${token}`;
      } else {
        params = `?&token=${token}`;
      }
    } catch (err) {
     console.log('webapi.services::getFunction::Exception:: ' + err);
    }
    return this.http.get(url + params, { headers: this.headers }).toPromise()
      .then(this.extractData).catch(this.handleError);
  }

  // Function for POST operations
  public postFunction(url, params): Promise<any> {
    try {
      params = (params != null) ? params : '';
      this.setHeaders();
    } catch (err) {
     console.log('webapi.services::postFunction::Exception:: ' + err);
    }
    return this.http.post(url, params, { headers: this.headers }).toPromise()
      .then(this.extractData).catch(this.handleError);
  }

  // Function for PUT operations
  public putFunction(url, params): Promise<any> {
    try {
      params = (params != null) ? params : '';
      this.setHeaders();
    } catch (err) {
     console.log('webapi.services::putFunction::Exception:: ' + err);
    }
    return this.http.put(url, params, { headers: this.headers }).toPromise()
      .then(this.extractData).catch(this.handleError);
  }

  // Function for DELETE operations
  public deleteFunction(url, params): Promise<any> {
    try {
      params = (params != null) ? params : '';
      this.setHeaders(true);
    } catch (err) {
     console.log('webapi.services::deleteFunction::Exception:: ' + err);
    }
    return this.http.delete(url + params, { headers: this.headers }).toPromise()
      .then(this.extractData).catch(this.handleError);
  }

  // Function for set the headers
  // Here the header object appends with Authorization(Bearer token),cityId and Content-Type parameters
  private setHeaders(isGet: boolean = false, skipAuthBearer: boolean = false) {
    try {
      if (localStorage.getItem('BI_USER_TOKEN') != null && localStorage.getItem('BI_USER_TOKEN').length > 0) {
        this.token = localStorage.getItem('BI_USER_TOKEN');
      }
      this.headers = new HttpHeaders();
      if (!skipAuthBearer) {
        this.headers = this.headers.set('Authorization', 'Bearer ' + this.token);
      }
      if (isGet) {
        this.headers = this.headers.set('Content-Type', 'application/json');
      }
    } catch (err) {
     console.log('webapi.services::setHeaders::Exception:: ' + err);
    }
  }

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  private handleError(err: any): Promise<any> {
    console.error('An error occurred', err);
    return Promise.reject(err.error || err);
  }
}
