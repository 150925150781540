import { Component, OnInit, ElementRef, HostBinding, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { Routes, Router } from '@angular/router';
import { JwtHelper } from 'angular2-jwt';
@Component({
    selector: 'app-page-header',
    templateUrl: './pageheader-component.html',
    styleUrls: ['./pageheader.scss']
})

export class PageHeaderComponent implements OnInit {

    public userName = '';

    ngOnInit() {
        const token = localStorage.getItem('BI_USER_TOKEN');
        if (token !== null) {
        const userData = new JwtHelper().decodeToken(token);
        this.userName = userData.nickname;
        }

        $('#search').on('click', function (e) {
            e.preventDefault();
            $('.search-box').fadeIn().addClass('display');
        });
        $('.dismiss').on('click', function () {
            $('.search-box').fadeOut().removeClass('display');
        });

        $('#toggle-btn').on('click', function (e) {
            e.preventDefault();
            $(this).toggleClass('active');

            $('#sidebar').toggleClass('shrinked');
            $('#sidebar').toggle();
            $('.content-inner').toggleClass('active');
            $(document).trigger('sidebarChanged');

            if ($(window).outerWidth() > 1183) {
                if ($('#toggle-btn').hasClass('active')) {
                    $('.navbar-header .brand-small').hide();
                    $('.navbar-header .brand-big').show();
                } else {
                    $('.navbar-header .brand-small').show();
                    $('.navbar-header .brand-big').hide();
                }
            }
        });

        const windowWidth = $(window).outerWidth();
        if (windowWidth <= 1199) {
            // $('#toggle-btn').show();
            $('#sidebar').hide();

        } else {
            // $('#toggle-btn').hide();
            $('#sidebar').show();
        }
    }

    logOut() {
        localStorage.removeItem('BI_USER_TOKEN');
    }

    @HostListener('window:resize', ['$event'])
    public onResize() {
        const windowWidth = $(window).outerWidth();
        if (windowWidth <= 1199) {
            // $('#toggle-btn').show();
            $('#sidebar').hide();

        } else {
            // $('#toggle-btn').hide();
            $('#sidebar').show();
        }
    }
}
