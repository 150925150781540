import {Component, Input, ViewContainerRef} from '@angular/core';
import {Routes, Router} from '@angular/router';

@Component({
  selector: 'pages',
  template: `
    <div class="al-content">
      <router-outlet></router-outlet>
    </div>

  `
})
export class Pages {

  constructor(private _router: Router) {

  }

  ngOnInit() {
    // this._menuService.updateMenuByRoutes(<Routes>PAGES_MENU);
  }
}
