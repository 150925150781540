import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { WebApiService } from '../../../shared/webapi.service';
import { UrlConstants } from '../../../shared/url.constants';
import { Router,ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.scss']
})
export class SidebarComponent implements OnInit {
  @Output() changeSubMetricChart: EventEmitter<any> = new EventEmitter<any>();
  @Output() retentionMetricsEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() appruEmit: EventEmitter<any> = new EventEmitter<any>();

  public filters = [
    { 'name': 'Room', 'value': 'room', 'checked': 'checked' },
    { 'name': 'VGifts', 'value': 'vgifts', 'checked': 'checked' },
    { 'name': 'PalPlus', 'value': 'palplus', 'checked': 'checked' }
  ];
  public fromDate;
  public filterProdLines = [];
  public filterProdDetails = [];
  public filterReportDesc = [];
  public filterPriceGrouped = [];
  public filterOfferTypes = [];
  public filterProductId = [];
  public filterCountryOfOrigin = [];
  public filterDurationDays = [];
  public filterClientTypes = [];
  public filterClean = [];
  public showloader = false;
  public selectedAll1: any;
  public selectedAll2: any;
  public selectedAll3: any;
  public selectedAll4: any;
  public selectedAll5: any;
  public selectedAll6: any;
  public selectedAll7: any;
  public selectedAll8: any;
  public selectedAll9: any;
  public selectedAll10: any;
  public newArr = [];
  public href: string = "";
  public items = [];
  public showDashboard = 0;
  public dashboardPaths = ["/admin/dasboard"];

  public showRetMetrics = 0;
  public retMetricPaths = ["/admin/dasboard", "/admin/transRevenue", "/admin/subRevenue", "/admin/vgift", "/admin/arppu", "/admin/monthlyRev"];
  public userActPaths = ["/admin/activity", "/admin/onlineUser", "/admin/activities", "/admin/newUser"];
  public userMetricsDrop = 0;
  public financeDrop = 0;
  public financeActPaths = ["/admin/finance?t=1","/admin/finance?t=2","/admin/finance?t=3","/admin/finance?t=4","/admin/finance?t=5","/admin/finance?t=6","/admin/finance?t=7","/admin/finance?t=8","/admin/finance?t=9","/admin/finance?t=10","/admin/finance?t=11","/admin/finance?t=12","/admin/finance?t=13"]

  // public showvGift1 = 0;
  // public vGiftPaths1 = ["/admin/vgift?t=41"];
  // public showvGift2 = 0;
  // public vGiftPaths2 = ["/admin/vgift?t=42"];
  // public showvGift3 = 0;
  // public vGiftPaths3 = ["/admin/vgift?t=43"];

  public revenueMetricsDrop = 0;
  public revenueMetricsPath = ["/admin/dashboard","/admin/transRevenue","/admin/subRevenue","/admin/vgift", "/admin/arppu",  "/admin/monthlyRev", "/admin/subDailyRev","/admin/revPayingSource","/admin/bogo"];
  // public revenueMetricsPath = ["/admin/dashboard","/admin/dashboard?t=1", "/admin/dashboard?t=2", "/admin/dashboard?t=3", "/admin/dashboard?t=4", "/admin/dashboard?t=5", "/admin/trans?t=6", "/admin/dashboard?t=7", "/admin/dashboard?t=8", "/admin/dashboard?t=9", "/admin/dashboard?t=10", "/admin/dashboard?t=12", "/admin/dashboard?t=13", "/admin/vgift?t=41", "/admin/vgift?t=42", "/admin/vgift?t=43"];
  // public vGiftMetricsDrop = 0;
  // public vGiftMetricsPath = ["/admin/vgift", "/admin/vgift?t=41", "/admin/vgift?t=42", "/admin/vgift?t=43"];

  public retenMetricPaths = ["/admin/retention","/admin/countryType", "/admin/retention?t=2", "/admin/retention?t=3", "/admin/retention?t=4", "/admin/retention?t=5", "/admin/retention?t=6", "/admin/retention?t=7","/admin/cohortUser","/admin/cohortCountry", "/admin/cohortClient", "/admin/retUser"];
  public retenMetricDrop = 0;
  public cmpgMetricPaths = ['/admin/cmpgMetrics', 'admin/manycamp', '/admin/marketingRept' ];
  public cmpgMetricDrop = 0;

  public analysisDrop = 0;
  public analysisPath = ['/admin/vwoTestAnalysis'];

  public autoRenewDrop = 0;
  public autoRenewPath = ['/admin/autoRenew']
  public manyCamDrop = 0;
  public manyCamPath = ['/admin/manycam?t=1','/admin/manycam?t=2','/admin/manycam?t=3','/admin/manycam?t=5','/admin/manycam?t=6']

  public churnPaths = ["/admin/churn"];
  public churnDrop = 0;

  public hourlyPaths = ["/admin/hourly"];
  public hourlyDrop = 0;

  public retentionTrendsPaths = ["/admin/retTrendByUser","/admin/retTrendByCountry"];
  public retentionTrendsDrop = 0;

  public paltalkMarketingDrop = 0;
  public paltalkMarketingPath = ['/admin/marketing?t=1','/admin/marketing?t=2','/admin/paltalkmarketing?t=3','/admin/paltalkmarketing?t=4','/admin/paltalkmarketing?t=5','/admin/paltalkmarketing?t=1','/admin/paltalkmarketing?t=2'];

  public ActiveSubsDrop = 0;
  public ActiveSubsPath = ['/admin/paltalkActiveSubs?t=1','/admin/paltalkActiveSubs?t=2','/admin/paltalkActiveSubs?t=3','/admin/paltalkActiveSubs?t=4','/admin/paltalkActiveSubs?t=5'];

  public url = this.router.url;
  public currentClickedMenu = "";
  constructor(private _webApiService: WebApiService, private router: Router, private route: ActivatedRoute) {

    if (this.url == '/admin/transRevenue') {
      this.currentClickedMenu = 'transRev';
    }
    if (this.url == '/admin/dashboard') {
      this.currentClickedMenu = 'subMetric';
    }
    if (this.url == '/admin/subRevenue') {
      this.currentClickedMenu = 'subMetric';
    }
    if(this.url == '/admin/vgift'){
      this.currentClickedMenu = 'vgift' // name change
    }
    if(this.url == '/admin/arppu'){
      this.currentClickedMenu = 'arppu' 
    }
    if(this.url == '/admin/churn'){
      this.currentClickedMenu = 'churn' 
    }
    if(this.url == '/admin/activity'){
      this.currentClickedMenu = 'activeUser' 
    }
    if(this.url == '/admin/onlineUser'){
      this.currentClickedMenu = 'onlineUser' 
    }
    if(this.url == '/admin/activities'){
      this.currentClickedMenu = 'activities' 
    }
    if(this.url == '/admin/newUser'){
      this.currentClickedMenu = 'newUser' 
    }
    if(this.url == '/admin/retention'){
      this.currentClickedMenu = 'retByTypes' 
    }if(this.url == '/admin/countryType'){
      this.currentClickedMenu = 'retByCountry' 
    }
    if(this.url == '/admin/cohortUser'){
      this.currentClickedMenu = 'cohortUser' 
    }
    if(this.url == '/admin/cmpgMetrics'){
      this.currentClickedMenu = 'cmpgMetrics' 
    }
    if(this.url == '/admin/cohortCountry'){
      this.currentClickedMenu = 'cohortCountry' 
    }
    if(this.url == '/admin/cohortClient'){
      this.currentClickedMenu = 'cohortClient' 
    }
    if(this.url == '/admin/retUser'){
      this.currentClickedMenu = 'retUser' 
    }
    if(this.url == '/admin/manycamp'){
      this.currentClickedMenu = 'manycamp' 
    }
    if(this.url == '/admin/monthlyRev'){
      this.currentClickedMenu = 'monthlyRev' 
    }
    if(this.url == '/admin/marketingRept'){
      this.currentClickedMenu = 'marketingRept' 
    }
    if(this.url == '/admin/vwoTestAnalysis'){
      this.currentClickedMenu = 'vwoTestAnalysis'
    } 
    if(this.url == '/admin/autoRenew'){
      this.currentClickedMenu = 'autoRenew'
    } 
    if(this.url == '/admin/subDailyRev'){
      this.currentClickedMenu = 'subDailyRev'
    } //
    if(this.url == '/admin/revPayingSource'){
      this.currentClickedMenu = 'revPayingSource'
    } 
    if(this.url == '/admin/retentionChart'){
      this.currentClickedMenu = '/admin/retentionChart'
    }
    if(this.url == '/admin/finance'){
      this.currentClickedMenu = '/admin/finance'
    }
    if(this.url == '/admin/bogo'){
      this.currentClickedMenu = '/admin/bogo'
    }

    if(this.url == '/admin/hourly'){
      this.currentClickedMenu = 'hourly' 
    }
    if(this.url == '/admin/retTrendByUser'){
      this.currentClickedMenu = 'retTrendByUser' 
    }
    if(this.url == '/admin/retTrendByCountry'){
      this.currentClickedMenu = 'retTrendByCountry' 
    }
    if(this.url == '/admin/manycam?t=1'){
      this.currentClickedMenu = 'dialyInstallation' 
    }
    if(this.url == '/admin/manycam?t=2'){
      this.currentClickedMenu = 'dailySummary' 
    }
    if(this.url == '/admin/manycam?t=3'){
      this.currentClickedMenu = 'dailyPurchase' 
    }
  
    if(this.url == '/admin/manycam?t=5'){
      this.currentClickedMenu = 'lifeTimeRev' 
    }
    if(this.url == '/admin/manycam?t=6'){
      this.currentClickedMenu = 'manycamspend' 
    }
    if(this.url == '/admin/marketing?t=1'){
      this.currentClickedMenu = 'regs' 
    }
    if(this.url == '/admin/marketing?t=2'){
      this.currentClickedMenu = 'channel' 
    }

    if(this.url == '/admin/paltalkmarketing?t=1'){
      this.currentClickedMenu = 'regs' 
    }
    if(this.url == '/admin/paltalkmarketing?t=2'){
      this.currentClickedMenu = 'channel' 
    }

    if(this.url == '/admin/paltalkmarketing?t=3'){
      this.currentClickedMenu = 'NGRegs' 
    }
    if(this.url == '/admin/paltalkmarketing?t=4'){
      this.currentClickedMenu = 'wordstream' 
    }
    if(this.url == '/admin/paltalkActiveSubs?t=1'){
      this.currentClickedMenu = 'paltalkactivesubs' 
    }
    if(this.url == '/admin/paltalkActiveSubs?t=2'){
      this.currentClickedMenu = 'vumberactivesubs' 
    }
    if(this.url == '/admin/paltalkActiveSubs?t=3'){
      this.currentClickedMenu = 'tinychatactivesubs' 
    }
    if(this.url == '/admin/paltalkActiveSubs?t=4'){
      this.currentClickedMenu = 'paltalkeomactivesubs' 
    }
    if(this.url == '/admin/paltalkActiveSubs?t=5'){
      this.currentClickedMenu = 'tinychateomactivesubs' 
    }
    if(this.url == '/admin/paltalkActiveSubs?t=6'){
      this.currentClickedMenu = 'paltalkmau' 
    }
   
    this.revenueMetricsDrop = this.revenueMetricsPath.includes(this.url) ? 1 : 0;
    this.retenMetricDrop = this.retenMetricPaths.includes(this.url) ? 1 : 0;
    this.churnDrop = this.retenMetricPaths.includes(this.url) ? 1 : 0;
    this.userMetricsDrop = this.userActPaths.includes(this.url) ? 1 : 0;
    this.cmpgMetricDrop = this.cmpgMetricPaths.includes(this.url) ? 1 : 0;
    this.analysisDrop = this.analysisPath.includes(this.url) ? 1 : 0;
    this.autoRenewDrop = this.autoRenewPath.includes(this.url) ? 1 : 0;
    this.financeDrop = this.financeActPaths.includes(this.url) ? 1 : 0;
    this.hourlyDrop = this.hourlyPaths.includes(this.url) ? 1 : 0;
    this.retentionTrendsDrop = this.retentionTrendsPaths.includes(this.url) ? 1 : 0;
    this.manyCamDrop = this.manyCamPath.includes(this.url) ? 1 : 0;
    this.paltalkMarketingDrop = this.paltalkMarketingPath.includes(this.url) ? 1 : 0;
    this.ActiveSubsDrop = this.ActiveSubsPath.includes(this.url) ? 1 : 0;
  }

  public resetActiveMenu(activeMenu, url, queryObj) { 
    this.currentClickedMenu = activeMenu;
    if (url != '' && this.url != url) {
      let qstr = queryObj != '' ? { t: queryObj } : {};
      this.router.navigate([url], {
        queryParams: qstr
      });
    }

    this.revenueMetricsDrop = this.revenueMetricsPath.includes(this.url) ? 1 : 0;
    this.retenMetricDrop = this.retenMetricPaths.includes(this.url) ? 1 : 0;
    this.churnDrop = this.churnPaths.includes(this.url) ? 1 : 0;
    this.userMetricsDrop = this.userActPaths.includes(this.url) ? 1 : 0;
    this.analysisDrop = this.analysisPath.includes(this.url) ? 1 : 0;
    this.cmpgMetricDrop = this.cmpgMetricPaths.includes(this.url) ? 1 : 0;
    this.autoRenewDrop = this.autoRenewPath.includes(this.url) ? 1 : 0;
    this.financeDrop = this.financeActPaths.includes(this.url) ? 1 : 0;
    this.hourlyDrop = this.hourlyPaths.includes(this.url) ? 1 : 0;
    this.manyCamDrop = this.manyCamPath.includes(this.url) ? 1 : 0;
    this.retentionTrendsDrop = this.retentionTrendsPaths.includes(this.url) ? 1 : 0;
    this.ActiveSubsDrop = this.ActiveSubsPath.includes(this.url) ? 1 : 0;
    this.paltalkMarketingDrop = this.paltalkMarketingPath.includes(this.url) ? 1 : 0;

  } 

  public loadFilters(queryParam) {
    if (queryParam === '' && (this.fromDate === '' || this.fromDate === undefined)) {
      const date = moment();
      const toDate = date.format('YYYY-MM-DD');
      const fromDate = date.subtract(7, 'd').format('YYYY-MM-DD');
      queryParam = '?fromDate=' + fromDate + '&toDate=' + toDate;
    }
    this.showloader = true;
    this._webApiService.getFunction(UrlConstants.getFilterData, queryParam).then((res) => {
      if (res.status === 'OK') {
        (res.result[0][0] || []).forEach(item => {
          this.filterProdLines.push({ id: item.prod_line, itemName: item.prod_line, itemAmt: item.amount, selected: false });
        });
        (res.result[1][0] || []).forEach(item => {
          this.filterProdDetails.push({ id: item.prod_line_details, itemName: item.prod_line_details, itemAmt: item.amount, selected: false });
        });
        (res.result[2][0] || []).forEach(item => {
          this.filterReportDesc.push({ id: item.report_desc, itemName: item.report_desc, itemAmt: item.amount, selected: false });
        });
        (res.result[3][0] || []).forEach(item => {
          this.filterPriceGrouped.push({ id: item.amt_grouped, itemName: item.amt_grouped, itemAmt: item.amount, selected: false });
        });
        (res.result[4][0] || []).forEach(item => {
          this.filterOfferTypes.push({ id: item.offer_type, itemName: item.offer_type, itemAmt: item.amount, selected: false });
        });
        (res.result[5][0] || []).forEach(item => {
          this.filterProductId.push({ id: item.product_id, itemName: item.product_id, itemAmt: item.amount, selected: false });
        });
        (res.result[6][0] || []).forEach(item => {
          this.filterCountryOfOrigin.push({ id: item.country_of_origin, itemName: item.country_of_origin, itemAmt: item.amount, selected: false });
        });
        (res.result[7][0] || []).forEach(item => {
          this.filterDurationDays.push({ id: item.duration_days_since_registration_grouped, itemName: item.duration_days_since_registration_grouped, itemAmt: item.amount, selected: false });
        });
        (res.result[8][0] || []).forEach(item => {
          this.filterClientTypes.push({ id: item.client_type, itemName: item.client_type, itemAmt: item.amount, selected: false });
        });
        (res.result[9][0] || []).forEach(item => {
          this.filterClean.push({ id: item.user_type, itemName: item.user_type, itemAmt: item.amount, selected: false });
        });
      }
      this.showloader = false;
    }).catch(
      (err) => {
        this.showloader = false;
        console.log(err);
      }
    );
  }

  filterOnChange(id, type) {
    if (type === 1) {
      this.selectedAll1 = this.filterProdLines.every(function (item: any) {
        return item.selected === true;
      });
    }
    if (type === 2) {
      this.selectedAll2 = this.filterProdDetails.every(function (item: any) {
        return item.selected === true;
      });
    }
    if (type === 3) {
      this.selectedAll3 = this.filterReportDesc.every(function (item: any) {
        return item.selected === true;
      });
    }
    if (type === 4) {
      this.selectedAll4 = this.filterPriceGrouped.every(function (item: any) {
        return item.selected === true;
      });
    }
    if (type === 5) {
      this.selectedAll5 = this.filterOfferTypes.every(function (item: any) {
        return item.selected === true;
      });
    }
    if (type === 6) {
      this.selectedAll6 = this.filterProductId.every(function (item: any) {
        return item.selected === true;
      });
    }
    if (type === 7) {
      this.selectedAll7 = this.filterCountryOfOrigin.every(function (item: any) {
        return item.selected === true;
      });
    }
    if (type === 8) {
      this.selectedAll8 = this.filterDurationDays.every(function (item: any) {
        return item.selected === true;
      });
    }
    if (type === 9) {
      this.selectedAll9 = this.filterClientTypes.every(function (item: any) {
        return item.selected === true;
      });
    }
    if (type === 10) {
      this.selectedAll10 = this.filterClean.every(function (item: any) {
        return item.selected === true;
      });
    }
  }

  selectAll(type) {
    if (type === 1) {
      for (let iterate = 0; iterate < this.filterProdLines.length; iterate++) {
        this.filterProdLines[iterate].selected = this.selectedAll1;
      }
    }
    if (type === 2) {
      for (let iterate = 0; iterate < this.filterProdDetails.length; iterate++) {
        this.filterProdDetails[iterate].selected = this.selectedAll2;
      }
    }
    if (type === 3) {
      for (let iterate = 0; iterate < this.filterReportDesc.length; iterate++) {
        this.filterReportDesc[iterate].selected = this.selectedAll3;
      }
    }
    if (type === 4) {
      for (let iterate = 0; iterate < this.filterPriceGrouped.length; iterate++) {
        this.filterPriceGrouped[iterate].selected = this.selectedAll4;
      }
    }
    if (type === 5) {
      for (let iterate = 0; iterate < this.filterOfferTypes.length; iterate++) {
        this.filterOfferTypes[iterate].selected = this.selectedAll5;
      }
    }
    if (type === 6) {
      for (let iterate = 0; iterate < this.filterProductId.length; iterate++) {
        this.filterProductId[iterate].selected = this.selectedAll6;
      }
    }
    if (type === 7) {
      for (let iterate = 0; iterate < this.filterCountryOfOrigin.length; iterate++) {
        this.filterCountryOfOrigin[iterate].selected = this.selectedAll7;
      }
    }
    if (type === 8) {
      for (let iterate = 0; iterate < this.filterDurationDays.length; iterate++) {
        this.filterDurationDays[iterate].selected = this.selectedAll8;
      }
    }
    if (type === 9) {
      for (let iterate = 0; iterate < this.filterClientTypes.length; iterate++) {
        this.filterClientTypes[iterate].selected = this.selectedAll9;
      }
    }
    if (type === 10) {
      for (let iterate = 0; iterate < this.filterClientTypes.length; iterate++) {
        this.filterClean[iterate].selected = this.selectedAll10;
      }
    }
  }

  onPageClick(type) {
    this.changeSubMetricChart.emit(type)
  }
 
  onRetentionClick(type){
    this.retentionMetricsEmit.emit(type);
  }

  onAppruClick(type){
    this.appruEmit.emit(type);
  }

  ngOnInit() {    
  }
}
