import {
 HttpEvent,
 HttpInterceptor,
 HttpHandler,
 HttpRequest,
 HttpResponse,
 HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { tap,retry, catchError } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router) {}
 intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   return next.handle(request)
     .pipe(
         retry(1),
         tap(response => {
                if (response instanceof HttpResponse) {
                    const token = response.headers.get('x_refresh_token') || null
                    if (token) {
                        localStorage.setItem('BI_USER_TOKEN', token);
                    }
                }
            }),
         catchError((error: HttpErrorResponse) => {
            const statusCode = error.status;
            if (statusCode === 401) {
                this.router.navigate(['unauthorized-access']);
            }
             
            if (statusCode === 403) {
                this.router.navigate(['session-expired']);
            }
            return throwError(error);
       })
     )
 }
}